
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/config";

export default defineComponent({
  name: "activity",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Platforms", ["Pages", "Profile", "Activity"]);
    });
  },
});
